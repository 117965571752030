import React, { type SVGAttributes } from "react"

import * as styles from "./BlackHalvaLogoV2.module.scss"

export function BlackHalvaLogoV2(props: SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 73 14" className={styles.icon} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.283 9.899a3.233 3.233 0 110-6.467 3.233 3.233 0 010 6.467zm0-9.899a6.668 6.668 0 105.09 10.953 4.944 4.944 0 01-1.816 1.936v.345h3.376V6.64A6.665 6.665 0 0021.293 0h-.01zm31.994 9.96H48.89V7.8h4.305c.54 0 1.167.51 1.167 1.082a1.088 1.088 0 01-1.085 1.078zM48.89 3.376h3.685a1.147 1.147 0 011.06 1.073 1.121 1.121 0 01-1.17 1.093H48.89V3.376zm7.385 2.891a3.981 3.981 0 00.143-4.122A4.127 4.127 0 0052.892.102h-7.376v13.132h8.252a3.984 3.984 0 004.032-3.93 3.685 3.685 0 00-1.532-3.032l.007-.005zM42.171 4.949c1.108 2.988 1.164 5.7 1.164 8.29h-3.417c0-2.102-.074-4.015-.51-5.744-.588-2.318-1.686-4.001-2.705-4.001-1.019 0-2.12 1.683-2.707 4.001-.442 1.73-.51 3.594-.51 5.744h-3.433c0-2.357.054-5.302 1.162-8.29C32.247 2.161 34.175 0 36.698 0c2.523 0 4.449 2.16 5.48 4.944l-.007.005zM9.873.11H13.3v1.382a6.638 6.638 0 01-2.467 5.18 6.662 6.662 0 012.467 5.181v1.382H9.873v-1.73a3.223 3.223 0 00-6.446 0v1.73H0v-1.382a6.655 6.655 0 012.467-5.181A6.655 6.655 0 010 1.49V.107h3.427v1.73a3.223 3.223 0 106.446 0V.106.11zm55.87 9.79a3.233 3.233 0 113.222-3.241 3.23 3.23 0 01-3.223 3.233V9.9zm6.639-3.274a6.66 6.66 0 10-1.558 4.32 4.962 4.962 0 01-1.816 1.936v.345h3.374V6.625z"
      />
    </svg>
  )
}
